<template>
  <v-card>
    <v-card-title>Самовывоз</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <text-line title="Дата получения" :value="order.deliveryDate" />
        </v-col>
        <v-col>
          <text-line title="Время посещения магазина" :value="order.deliveryTimeSlot | timeSlot" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <text-line title="Магазин" :value="deliveryShop" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TextLine from '@/components/ui/TextLine.vue'

export default {
  components: { TextLine },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    deliveryShop() {
      if (this.order.deliveryShop === null) {
        return ''
      }

      return `${this.order.deliveryShop.name}: ${this.order.deliveryShop.address}`
    }
  }
}
</script>
